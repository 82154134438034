/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

jQuery.extend( jQuery.fn.dataTableExt.oSort, {
    "formatted-num-pre": function ( a ) {
        a = (a === "-" || a === "") ? 0 : a.replace( /[^\d\-\.]/g, "" );
        return parseFloat( a );
    },
 
    "formatted-num-asc": function ( a, b ) {
        return a - b;
    },
 
    "formatted-num-desc": function ( a, b ) {
        return b - a;
    }
} );

    // Disable scroll when focused on a number input.
    $('form').on('focus', 'input[type=number]', function(e) {
        $(this).on('wheel', function(e) {
            e.preventDefault();
        });
    });
 
    // Restore scroll on number inputs.
    $('form').on('blur', 'input[type=number]', function(e) {
        $(this).off('wheel');
    });
 
    // Disable up and down keys.
    $('form').on('keydown', 'input[type=number]', function(e) {
        if ( e.which === 38 || e.which === 40 ) {
            e.preventDefault();
        }
    });


  if ($(window).width() <= 740) {
    $('.contact-details').remove().insertBefore($('.contact-text'));
  } else {
    $('.contact-details').remove().insertAfter($('.contact-text'));
  }



  /*$(".main-pic-btn-outer").click(function(event){
      $('html, body').animate({scrollTop: '+=200px'}, 800);
  });*/

  jQuery(document).ready(function($) {
    $('a[data-rel^=lightcase]').lightcase({
      swipe: true,
      maxWidth: 2000,
      maxHeight: 2000,
      labels: {
        'sequenceInfo.of': ' av ',
        'close': 'Lukk',
        'navigator.prev': 'Forrige',
        'navigator.next': 'Neste',
        'navigator.play': 'Spill av',
        'navigator.pause': 'Pause'
      }
    });
  });

  $(document).ready(function() {
      $('#property-1').DataTable( {
          autoWidth: false, //Enten sette denne eller sette .css('width', '100%') der den er kommentert ut nedenfor
          responsive: true,
          "paging":   false,
          "info":     false,
          "bFilter": false
      } );
  } );

  $(document).ready(function() {
      $('#property-2').DataTable( {
          autoWidth: false,
          responsive: true,
          "paging":   false,
          "info":     false,
          "bFilter": false
      } );
  } );

    $(document).ready(function() {
      $('#property-3').DataTable( {
          autoWidth: false,
          responsive: true,
          "paging":   false,
          "info":     false,
          "bFilter": false
      } );
  } );

      $(document).ready(function() {
      $('#property-4').DataTable( {
          autoWidth: false,
          responsive: true,
          "paging":   false,
          "info":     false,
          "bFilter": false
      } );
  } );

        $(document).ready(function() {
      $('#property-5').DataTable( {
          autoWidth: false,
          responsive: true,
          "paging":   false,
          "info":     false,
          "bFilter": false
      } );
  } );

$(document).ready(function(){
  
    $('ul.tabs').on('click', 'li', function() {
    var tab_id = $(this).attr('data-tab');

    $('ul.tabs li').removeClass('current');
    $('.tab-content').removeClass('current');

    $(this).addClass('current');
    $("#"+tab_id).addClass('current');

    //$( $.fn.dataTable.tables(true) ).DataTable().responsive.recalc();
    //$( $.fn.dataTable.tables(true) ).DataTable().columns.adjust();
    //$($.fn.dataTable.tables( true ) ).css('width', '100%');
    //$($.fn.dataTable.tables( true ) ).DataTable().columns.adjust().draw();

  });
});

$(document).ready(function() {
  $('.hamburger').on('click', function() {
         if(!$(this).hasClass('is-active')) {
            $('body').css({'overflow':'hidden'});
            $('.overlay').addClass('open');
            $(this).addClass('is-active');
         }
         else {
            $('body').css({'overflow':'inherit'});
            $(this).removeClass('is-active');
            $('.overlay').removeClass('open');
         }
  });
});

$("#open").click(function () {
    if ($(this).parent().css("right") === "-495px") {
      $(this).parent().animate({right:'0px'}, {queue: false, duration: 500});
    } else {
    $(this).parent().animate({right:'-495px'}, {queue: false, duration: 500}); }
});

$(document).on('mouseup', function (e)
{
    var divBort = $('#slideout');
    if (!divBort.is(e.target) && divBort.has(e.target).length === 0)
      {
        if ($(this).find("#slideout").css("right") === "0px") {
          $(this).find("#slideout").animate({right:'-495px'}, {queue: false, duration: 500});
      }
    }
});

$(window).scroll(function() {    
    var scroll = $(window).scrollTop();
    if (scroll >= 30) {
        $(".main-content-outer").addClass("whoop");
    }
    else if (scroll <= 30) {
        $(".main-content-outer").removeClass("whoop");
    }
});

function startupAnimes() {

  $(".top-header").animate({ "top":"0px" },{ duration:650 }).promise().done(function () {
      $(".hytte-main-pic-container-outer").delay(400).animate({"opacity":"1"},{duration:1200 });
  });

} 
startupAnimes();

$(".read-more-arrow").click(function() {
  $([document.documentElement, document.body]).animate({
      scrollTop: $("#point-container").offset().top - 600
  }, 1000);
});

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page


        function startupAnime() {

          $(".top-header").animate({ "top":"0px" },{ duration:650 }).promise().done(function () {

              $(".main-pic-container-outer").delay(400).animate({"opacity":"1"},{duration:1200 });
              $(".top-buttons").delay(100).animate({"right":"0px"},{duration:350 });
              setInterval(function(){
                  $(".top-header").find(".logo").fadeIn(1000);
                  }, 1050);
                  new Vivus('Layer_1', {duration: 270, type:"sync"}, function (obj) {
                    obj.el.classList.add('finished');
                  });

          });

        } 
        startupAnime();


       /* function sprette(element, times, distance, speed) {
            for(i = 0; i < times; i++) {
                element.animate({top: '-='+distance},speed)
                    .animate({top: '+='+distance},speed);
            }        
        }

        sprette($("#bounce"), 20, '6px', 600);*/
       

        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.